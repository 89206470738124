import * as React from 'react';
import { FC } from 'react';
import Button from '../../../ui/button';

const SectionCtas: FC<{
  title?: string;
  text?: string;
  columns: any;
  classes?: string;
}> = ({ title, text, columns, classes }) => {
  return (
    <section className={`${classes} section section-ctas`}>
      <div className="container">
        {title && (
          <div className="columns is-centered">
            <div className="column is-8 is-12-mobile is-12-tablet">
              <h2
                className="title title-large has-text-centered text-black"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <p>{text}</p>
            </div>
          </div>
        )}
        <div className="columns is-centered">
          {Object.keys(columns).map((key) => {
            const { ctaText, ctaUrl, text: columnText, alt } = columns[key];
            return (
              <div key={key} className="column is-4 is-12-mobile is-4-tablet ">
                <div className="cta_box">
                  <p className=" text-medium">{columnText}</p>
                  <Button to={ctaUrl}>{ctaText}</Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

SectionCtas.defaultProps = {
  classes: ` default `,
  text: null,
  title: null,
};
export default SectionCtas;
