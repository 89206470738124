import * as React from 'react';
import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { ColSlider } from '../../common/elements/Slider';
import { ImageGallery } from '../../../ui/imageGallery';

const SectionAdvantages: FC<{
  title?: string;
  text?: string;
  columns: any;
  classes?: string;
}> = ({ title, text, columns, classes }) => {
  return (
    <section className={`${classes} section section-adavantages`}>
      <div className="container">
        {title && (
          <div className="columns is-centered">
            <div className="column is-8 is-12-mobile is-12-tablet">
              <h2
                className="title title-large has-text-centered text-black"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <p className="sub_title">{text}</p>
            </div>
          </div>
        )}
        <div className="columns is-centered">
          {Object.keys(columns).length <= 3 || isMobileOnly ? (
            Object.keys(columns).map((key) => {
              const {
                title: columnTitle,
                text: columnText,
                image,
                alt,
              } = columns[key];
              return (
                <div
                  key={key}
                  className="column is-4 is-12-mobile  is-4-tablet has-text-centered"
                >
                  <ImageGallery
                    name={image}
                    alt={alt || ''}
                    className="column-icon"
                  />
                  <p className="has-text-centered text-medium bold">
                    {columnTitle}
                  </p>
                  <p className="has-text-centered text-medium">{columnText}</p>
                </div>
              );
            })
          ) : (
            <ColSlider slides={columns} />
          )}
        </div>
      </div>
    </section>
  );
};

SectionAdvantages.defaultProps = {
  classes: ` default `,
  text: null,
  title: null,
};
export default SectionAdvantages;
