import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedPageProps, IColumnList } from '../utils/types';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { SectionCommunity } from '../components/partials/partner/section-community';
import { SectionWhy } from '../components/partials/partner/section-why-you';
import { SectionEarn } from '../components/partials/partner/section-earn';
import SectionCtas from '../components/partials/partner/section-ctas';
import SectionAdvantages from '../components/partials/partner/section-advantages';
import { HeroPartner } from '../components/partials/common/hero';
import { ReferralSection } from '../components/sections/referral-section';
import { FlipCard } from '../components/partials/common/elements/Card';
import SectionTestimonial from '../components/partials/partner/section-testimonials';

const ReferralPage: FC<LocalizedPageProps> = ({ pageContext }) => {
  const localKey = `${pageContext.key}`;
  const { t } = useTranslation(localKey);

  const { lang, alternateUrls } = pageContext;

  const howItWorksCards: any = t('howItWorks.cards', {
    returnObjects: true,
  });
  const advantages: IColumnList = t('advantages.messages', {
    returnObjects: true,
  });

  const ctas: any = t('cta_boxes', {
    returnObjects: true,
  });

  let earn = false;
  const earnCards: any = t('earnAsPratnerNew.cards', {
    returnObjects: true,
  });
  if (typeof earnCards === 'object') earn = true;

  return (
    <Layout pageContext={pageContext}>
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <HeroPartner translation={t} />
      <section className="howItWorksCards is-centered">
        <div className="columns is-centered">
          <h2 className="title title-large text-black has-text-centered">
            {t('howItWorks.title')}{' '}
          </h2>
        </div>
        <div className="columns is-centered">
          <FlipCard card={howItWorksCards['1']} classes="how_flip" />
          <FlipCard card={howItWorksCards['2']} classes=" how_flip " />
        </div>
      </section>
      <SectionWhy translation={t} />
      <SectionCtas classes="" columns={ctas} />
      <SectionAdvantages
        classes=""
        title={t('advantages.title')}
        text={t('advantages.text')}
        columns={advantages}
      />
      {earn && <SectionEarn localKey={localKey} translation={t} />}
      {/*  <SectionCommunity translation={t} /> */}
      <SectionTestimonial translation={t} />
      <ReferralSection translation={t} />
    </Layout>
  );
};

export default ReferralPage;
