/* eslint-disable react/no-danger */
import * as React from 'react';
import { FC, useState } from 'react';
import { TFunction } from 'i18next';
import { isMobileOnly } from 'react-device-detect';
import { Image } from '../../../ui/image';
import { ImageGallery } from '../../../ui/imageGallery';

const SectionWhy: FC<{ translation: TFunction }> = ({ translation }) => {
  const [toggle, setToggle] = useState<number | ''>(0);

  const list: any = translation('whyYou.boxes', {
    returnObjects: true,
  });

  return (
    <section className={` section section-whyYou`}>
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-9 is-12-mobile">
            <h3
              className="title title-large text-black has-text-centered"
              dangerouslySetInnerHTML={{
                __html: `${translation('whyYou.title')}`,
              }}
            />
            <p className="sub_text">{translation('whyYou.text')}</p>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-5 is-12-mobile">
            <div>
              {Object.keys(list).map((key, i) => {
                const { question, q1, a, icon } = list[key];
                return (
                  <>
                    <button
                      key={key}
                      className={`is-vcentered question ${
                        toggle === i && 'active'
                      }`}
                      onClick={() => setToggle(i)}
                      type="button"
                      onKeyDown={() => setToggle(i)}
                    >
                      <p className="text-medium">{question}</p>
                      <Image
                        name="arrow_right.svg"
                        alt=""
                        className="arrow_icon"
                      />
                    </button>
                    {isMobileOnly && toggle === i && (
                      <div key={key} className="answer mobile_a">
                        <div className="answer_hero">
                          <ImageGallery
                            name={icon}
                            alt={icon}
                            className="answer_icon"
                          />
                        </div>
                        <h3 className="title-medium">{question}</h3>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: `${q1}`,
                          }}
                        />
                        <p
                          dangerouslySetInnerHTML={{
                            __html: `${a}`,
                          }}
                        />
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
          <div className="column is-5 is-12-mobile answers">
            {!isMobileOnly &&
              Object.keys(list).map((key, i) => {
                const { question, q1, a, icon } = list[key];
                return toggle === i ? (
                  <div key={key} className="answer">
                    <div className="answer_hero">
                      <Image name={icon} alt="" className="answer_icon" />
                    </div>
                    <h3 className="title-medium">{question}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: `${q1}`,
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: `${a}`,
                      }}
                    />
                  </div>
                ) : null;
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export { SectionWhy };
