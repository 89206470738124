/* eslint-disable react/no-danger */
import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';
import { isMobileOnly } from 'react-device-detect';

import { ImageGallery } from '../../../ui/imageGallery';

const SectionTestimonial: FC<{ translation: TFunction }> = ({
  translation,
}) => {
  const testimonials: any = translation('testimonials', {
    returnObjects: true,
  });
  return (
    <section className={` section section-testimonials`}>
      <div className="container">
        <div className="partner_testimonial">
          <div className="columns is-centered">
            <div className="column is-5 is-12-mobile is-vcentered">
              {!isMobileOnly && (
                <ImageGallery name={testimonials.max.image} alt=" " />
              )}
              {isMobileOnly && (
                <ImageGallery
                  name={testimonials.max.image_mobile}
                  alt="partner logo"
                  className=""
                />
              )}
            </div>
            <div className="column is-7 is-12-mobile is-vcentered">
              <div className="column_content">
                <p className="testimonial_text">"{testimonials.max.text}"</p>
                <p>
                  <b>{testimonials.max.name},</b> {testimonials.max.company}
                </p>

                <ImageGallery
                  name={testimonials.max.logo}
                  alt="partner logo"
                  className="partner_logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionTestimonial;
